import { useTranslation } from "react-i18next";
import { Watermark } from "../../template";
import PngExportable from "../PngExportable";
import Table from "../Table";
import Day from "../Day";
import Unit from "../Unit";
import Location from "../Location";
import Hour from "../Hour";
import TableExportable from "../TableExportable";
import CreatedBy from "../CreatedBy";

const StepReport = ({
	start,
	end,
	day,
	unit,
	template,
	location,
	hour,
	table,
	createdby,
}) => {
	const { t } = useTranslation();
	return (
		<div>
			<PngExportable title={t(`report_title.step_day`, { start, end })}>
				<div style={{ height: 500 }} className="relative">
					<Watermark />
					<Day
						data={day.map(({ _id, total }) => ({
							_id,
							[t(`total`)]: total,
						}))}
					></Day>
				</div>
			</PngExportable>
			<PngExportable title={t(`report_title.step_template`, { start, end })}>
				<div className="relative">
					<Watermark />
					<Table data={template} columns={["total"]} />
				</div>
				<div className="mt-4" style={{ height: 600 }}>
					<Unit
						data={template.map(({ total, name }) => {
							return {
								name,
								children: [
									{
										name: t(`total`),
										total,
										color: "#008000",
									},
								],
							};
						})}
					></Unit>
				</div>
			</PngExportable>
			<PngExportable title={t(`report_title.step_unit`, { start, end })}>
				<div className="relative">
					<Watermark />
					<Table data={unit} tPath="const.step_status" columns={["total"]} />
				</div>
				<div className="mt-4" style={{ height: 600 }}>
					<Unit
						data={unit.map(({ total, name }) => {
							return {
								name,
								children: [
									{
										name: t(`total`),
										total,
										color: "#008000",
									},
								],
							};
						})}
					></Unit>
				</div>
			</PngExportable>

			<PngExportable
				title={t(`report_title.step_createdby`, { start, end, top: 10 })}
			>
				<div className="relative">
					<Watermark />
					<CreatedBy data={createdby}></CreatedBy>
				</div>
			</PngExportable>
			<PngExportable title={t(`report_title.step_hour`, { start, end })}>
				<div className="relative">
					<Watermark />
					<Hour
						data={hour}
						renderColor={(value) => {
							if (value < 6) return "bg-def-green text-white";
							if (value > 5 && value < 11) return "bg-def-secondary text-white";
							if (value > 10) return "bg-def-red text-white";
						}}
					/>
				</div>
			</PngExportable>
			<PngExportable title={t(`report_title.step_location`, { start, end })}>
				<div className="relative">
					<Watermark />
					<Table
						data={location}
						tPath="const.step_status"
						columns={["total"]}
					/>
				</div>
				<div className="mt-4">
					<Location data={location} />
				</div>
			</PngExportable>
			<div className="relative">
				<Watermark />
				<TableExportable
					title={t(`report_title.step_table`, { start, end })}
					find={{ start, end }}
					url="/report/step/export"
					data={table}
					columns={[
						{
							label: t("fields.template"),
							render: { type: "string", field: "template.name" },
						},
						{
							label: t("fields.location"),
							render: { type: "string", field: "location.name" },
						},
						{
							label: t("fields.unit"),
							render: { type: "string", field: "unit.name" },
						},
						{
							label: t("fields.createdby"),
							render: ({ createdby }) =>
								`${createdby.firstname} ${createdby.lastname} (${createdby.position})`,
						},
						{
							label: t("step.percent"),
							render: { type: "string", field: "percent" },
						},
						{
							label: t("fields.created"),
							render: { type: "date", field: "created" },
						},
					]}
				/>
			</div>
		</div>
	);
};

export default StepReport;
