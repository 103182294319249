import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import objectPath from "object-path";
import { Loader } from "../template";
import {
	RiArrowUpLine,
	RiArrowDownLine,
	RiArrowUpDownLine,
	RiDownload2Line,
} from "react-icons/ri";
import { mainApi } from "../utils/api";
import TableFilter from "./TableFilter";
import { DEBUG } from "../defines";
import { exportToExcel } from "../utils/excel";

const LIMITS = [3, 5, 10, 20, 50, 100];

const paginationItemclass =
	"h-10 w-10 flex items-center justify-center m-1 cursor border text-sm rounded";
const thClass = "py-3 px-2 border text-sm font-normal leading-none";
const tdClass = "py-3 px-2 border-b";

const Table = ({
	url,
	needfetch,
	find: propsFind,
	sort: propsSort,
	limit: propsLimit = 20,
	defaultFind,
	children,
	columns: propsColumns = [],
	paginationHide,
	totalHide,
	renderItem,
	renderHeader,
	renderFooter,
	singleSort,
	height = 400,
	onSelected,
	removed,
	exportable,
}) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [total, setTotal] = useState(0);
	const [items, setItems] = useState([]);
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(propsLimit);
	const [find, setFind] = useState({});
	const [sort, setSort] = useState({});
	const [selected, setSelected] = useState();
	const columns = propsColumns.filter((c) => c);

	const [checked, setChecked] = useState({});

	useEffect(() => {
		const fetchData = async ({ find, sort, offset, limit }) => {
			setLoading(true);

			const data = {
				find: { ...find, ...propsFind, ...defaultFind },
				sort: { ...sort, ...propsSort },
				offset,
				limit: limit || propsLimit,
			};

			const response = await mainApi({
				url,
				method: "POST",
				data,
			});

			if (response) {
				setItems(response.data.items);
				setTotal(response.data.total);
			}

			setLoading(false);

			DEBUG && console.log(url, response.data);
		};

		fetchData({ find, sort, offset, limit });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [url, find, sort, offset, limit, needfetch]);

	const download = async () => {
		const data = {
			find: { ...find, ...propsFind, ...defaultFind },
			sort: { ...sort, ...propsSort },
			limit: 3000,
		};

		const response = await mainApi({
			url,
			method: "POST",
			data,
		});

		await exportToExcel(exportable, columns, response.data.items);

		setLoading(false);
	};

	useEffect(() => {
		setLimit(propsLimit);
	}, [propsLimit]);

	const renderTableHeader = ({ key, label, sortable, center }, index) => {
		return (
			<th key={index} className={`${thClass} bg-def-gray`}>
				<div
					className={`flex items-center select-none ${
						sort[key] ? "font-bold underline" : ""
					} ${sortable ? "cursor" : ""} ${center ? "justify-center" : ""}`}
					onClick={() => {
						sortable && sortChange(key);
					}}
				>
					<div>{t(label)} </div>
					<div>
						{sortable &&
							(sort[key] ? (
								sort[key] === 1 ? (
									<RiArrowUpLine className="ml-1"></RiArrowUpLine>
								) : (
									<RiArrowDownLine className="ml-1"></RiArrowDownLine>
								)
							) : (
								<RiArrowUpDownLine className="ml-1"></RiArrowUpDownLine>
							))}
					</div>
				</div>
			</th>
		);
	};

	const filterChange = (key, value) => {
		let tmp = { ...find };

		if (value) {
			tmp[key] = value;
		} else {
			delete tmp[key];
		}

		setFind(tmp);
		setOffset(0);
	};

	const sortChange = (key) => {
		let tmp = { ...sort };

		if (singleSort) {
			if (tmp[key]) {
				if (tmp[key] === 1) {
					tmp[key] = -1;
				} else if (tmp[key] === -1) {
					tmp = {};
				}
			} else {
				tmp = { [key]: 1 };
			}
		} else {
			if (tmp[key] === 1) {
				tmp[key] = -1;
			} else if (tmp[key] === -1) {
				delete tmp[key];
			} else tmp[key] = 1;
		}

		setSort(tmp);
	};

	const onChecked = (row) => {
		const tmp = { ...checked };

		if (tmp[row._id]) {
			delete tmp[row._id];
		} else {
			tmp[row._id] = row;
		}

		setChecked(tmp);
	};

	const onCheckedAll = () => {
		let all = true;
		const tmp = { ...checked };

		items.forEach((i) => {
			if (!checked[i._id]) all = false;
		});

		items.forEach((i) => {
			if (all) {
				delete tmp[i._id];
			} else {
				tmp[i._id] = i;
			}
		});

		setChecked(tmp);
	};

	useEffect(() => {
		const tmp = { ...checked };

		delete tmp[removed?.id];

		setChecked(tmp);
		// eslint-disable-next-line
	}, [removed]);

	useEffect(() => {
		if (onSelected) {
			onSelected(checked);
		}
		// eslint-disable-next-line
	}, [checked]);

	return (
		<div className="relative">
			{loading && <Loader />}
			{renderHeader && renderHeader({ total })}
			{exportable && (
				<div className="flex justify-end mb-5">
					<div>
						<button
							className="secondary"
							type="button"
							onClick={() => {
								download();
							}}
						>
							<RiDownload2Line className="text-2xl mr-2" />
							{t("button.download")}
						</button>
					</div>
				</div>
			)}
			{children && children({ filterChange, sortChange })}
			<div
				className={`w-full overflow-x-auto text-sm bg-white ${
					renderItem ? "" : "border-l border-r"
				}`}
				style={{ minHeight: height }}
			>
				{columns.length > 0 ? (
					<table className="min-w-full leading-none text-left">
						<thead>
							<tr>
								{onSelected && (
									<th className={`${thClass} bg-def-gray text-center`}></th>
								)}
								<th className={`${thClass} bg-def-gray text-center`}>#</th>
								{columns.map(renderTableHeader)}
							</tr>
							<tr>
								{onSelected && (
									<th className={`${thClass} bg-white text-center`}>
										<div
											onClick={() => onCheckedAll()}
											className="cursor underline"
										>
											{t("list.select_all")}
										</div>
									</th>
								)}
								<td className={`${thClass} bg-white`}></td>
								{columns.map(
									({ key, filter, dependents, width = 170 }, index) => (
										<td
											key={index}
											className={`border bg-white`}
											style={{ minWidth: width }}
										>
											{filter && (
												<TableFilter
													filterChange={filterChange}
													field={key}
													columns={columns}
													dependents={dependents}
													{...filter}
												/>
											)}
										</td>
									)
								)}
							</tr>
						</thead>
						<tbody>
							{items.map((row, rowIndex) => (
								<tr
									key={rowIndex}
									className={`${
										selected === row._id
											? "hover:opacity-90 bg-def-secondary"
											: "hover:bg-def-gray"
									}`}
									onClick={() => {
										!onSelected &&
											setSelected(selected === row._id ? null : row._id);
									}}
								>
									{onSelected && (
										<td className={`${tdClass}`}>
											<input
												type="checkbox"
												checked={!!checked[row._id]}
												onChange={() => {
													onChecked(row);
												}}
											></input>
										</td>
									)}
									<td className={`${tdClass} px-4 text-center`}>
										{offset * limit + rowIndex + 1}
									</td>
									{columns.map(({ key, render, center }, columnIndex) => (
										<td
											key={columnIndex}
											className={`${tdClass} ${center ? "text-center" : ""}`}
										>
											{render ? render(row) : objectPath.get(row, key)}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				) : (
					renderItem && items.map((item, index) => renderItem(item, index))
				)}
				{items.length === 0 && (
					<div className="text-center leading-none p-4">{t("list.empty")}</div>
				)}
			</div>
			{renderFooter && renderFooter()}
			{!paginationHide && (
				<div className="flex flex-wrap justify-center items-center mt-5 text-sm">
					<div className="flex items-center">
						{t("list.limit")}:
						<select
							className="text-center h-10 ml-1 cursor"
							value={limit}
							onChange={(e) => setLimit(e.target.value)}
						>
							{LIMITS.map((item) => (
								<option key={item} value={item}>
									{item}
								</option>
							))}
						</select>
					</div>
					<ReactPaginate
						onPageChange={(e) => setOffset(e.selected)}
						pageCount={Math.ceil(total / limit)}
						containerClassName="flex flex-wrap justify-center items-center select-none mx-2"
						nextLabel=">"
						previousLabel="<"
						nextLinkClassName={paginationItemclass}
						previousLinkClassName={paginationItemclass}
						pageLinkClassName={paginationItemclass}
						breakLinkClassName={paginationItemclass}
						activeLinkClassName="bg-def-primary text-white"
						disabledClassName="cursor-not-allowed"
					/>
					{!totalHide && (
						<div>
							{t("list.total")}: {total}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default Table;
