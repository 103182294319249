import { useTranslation } from "react-i18next";
import { CreatedBy, Loader, Progress } from "../template";
import { Pie } from "../charts";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useState } from "react";
import { mainApi } from "../utils/api";
import { DEBUG } from "../defines";
import * as XLSX from "xlsx";
import objectPath from "object-path";
import { format, parseISO } from "date-fns";

const SurveyView = ({ id, createdby, created, name, results, percent }) => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);

	const onClickDownload = async () => {
		try {
			setLoading(true);
			const response = await mainApi(`/survey/export/${id}`);
			await exportToExcel("Survey", results, response.data);
		} catch (error) {
			DEBUG && console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const exportToExcel = (filename, results, rows) => {
		const headers = [
			{
				label: "survey.status",
				render: (row) => {
					const status = t(`survey.${row.status}`);
					return status;
				},
			},
			{
				label: "survey.ended",
				render: (row) =>
					row.ended && format(parseISO(row.ended), "yyyy-MM-dd HH:mm:ss"),
			},
			{
				label: "unit.name",
				key: "unit.name",
			},
			{
				label: "user.lastname",
				key: "employee.lastname",
			},
			{
				label: "user.firstname",
				key: "employee.firstname",
			},
		];

		const labels = headers.map(({ label }) => t(label));

		results.forEach(({ question }) => {
			labels.push(question);
		});

		const data = rows.map((row) => {
			const r = headers.map(({ key, render }) =>
				key ? objectPath.get(row, key) : render(row)
			);

			row.answers.map(({ response }) => r.push(response));

			return r;
		});

		let wb = XLSX.utils.book_new();
		let ws = XLSX.utils.json_to_sheet([labels, ...data], {
			skipHeader: true,
		});

		ws["!cols"] = labels.map(() => ({
			wch: 25,
		}));

		XLSX.utils.book_append_sheet(wb, ws);

		return XLSX.writeFile(wb, `${filename}.xlsx`);
	};

	return (
		<div className="w-full md:w-1/2 gap-8 mx-auto relative">
			{loading && <Loader></Loader>}
			<div className="flex justify-end mb-4">
				<div>
					<button
						type="button"
						className="secondary"
						onClick={() => {
							onClickDownload();
						}}
					>
						<RiFileExcel2Fill className="text-2xl mr-2" />
						{t("button.download")}
					</button>
				</div>
			</div>
			<div>
				<CreatedBy user={createdby} date={created}></CreatedBy>
				<div className="text-center">{name}</div>
				<div className="my-5">
					<Progress progress={percent} hideLabel={true}></Progress>
				</div>
				{results.map((result, index) => {
					const { question, type, total, options } = result;

					return (
						<div key={index} className="mb-5 pb-5 border-b">
							{index + 1}. {question}
							<div>
								{t("survey.response_total")}: {total}
							</div>
							{["multiple_choices", "single_choice"].includes(type) && (
								<Pie
									height={300}
									data={options}
									nameKey="option"
									dataKey="total"
								/>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SurveyView;
