import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	RiAlarmWarningLine,
	RiAlertLine,
	RiArticleLine,
	RiBallPenLine,
	RiBroadcastLine,
	RiChat1Line,
	RiCheckboxMultipleLine,
	RiGhostLine,
	RiIndeterminateCircleLine,
	RiMapPin2Line,
	RiMeteorLine,
	RiNewspaperLine,
	RiOrganizationChart,
	RiTeamLine,
	RiUserFollowLine,
	RiUserLine,
} from "react-icons/ri";
import { Pie } from "../charts";
import FileStats from "../org/FileStats";
import { CardIcon, Loader, Progress, Pulse } from "../template";
import { mainApi } from "../utils/api";
import { formatSize } from "../utils/format";
import UserBrief from "../user/Breif";
import { Link } from "react-router-dom";
import { useAuth } from "../utils/auth";
import OrgView from "../org/View";

const TOP = [
	{
		field: "hazard",
		icon: <RiAlertLine />,
		value: ({ hazard, hazardFocusable }) => (
			<div>
				<span className="text-base">{hazardFocusable} / </span>
				{hazard}
			</div>
		),
		progress: ({ hazard, hazardFocusable }) => hazardFocusable / hazard,
		progressColor: "bg-def-red",
	},
	{
		field: "violation",
		icon: <RiIndeterminateCircleLine />,
		value: ({ violation, violationFocusable }) => (
			<div>
				<span className="text-base">{violationFocusable} / </span>
				{violation}
			</div>
		),
		progress: ({ violation, violationFocusable }) =>
			violationFocusable / violation,
		progressColor: "bg-def-secondary",
	},
	// {
	// 	field: "inspection",
	// 	icon: <RiFindReplaceLine />,
	// 	value: ({ inspection, inspectionFocusable }) => (
	// 		<div>
	// 			<span className="text-base">{inspectionFocusable} / </span>
	// 			{inspection}
	// 		</div>
	// 	),
	// 	progress: ({ inspection, inspectionFocusable }) =>
	// 		inspectionFocusable / inspection,
	// 	progressColor: "bg-def-primary",
	// },
	{
		field: "permission",
		icon: <RiUserFollowLine />,
		value: ({ permission, permissionFocusable }) => (
			<div>
				<span className="text-base">{permissionFocusable} / </span>
				{permission}
			</div>
		),
		progress: ({ permission, permissionFocusable }) =>
			permissionFocusable / permission,
		progressColor: "bg-def-green",
	},
	{
		field: "incident",
		icon: <RiMeteorLine />,
		value: ({ incident, incidentFocusable }) => (
			<div>
				<span className="text-base">{incidentFocusable} / </span>
				{incident}
			</div>
		),
		progress: ({ incident, incidentFocusable }) => incidentFocusable / incident,
		progressColor: "bg-def-red",
	},
	{
		field: "cause",
		icon: <RiGhostLine />,
		value: ({ cause, incidentFocusable }) => (
			<div>
				<span className="text-base">{incidentFocusable} / </span>
				{cause}
			</div>
		),
		progress: ({ cause, incidentFocusable }) => incidentFocusable / cause,
		progressColor: "bg-def-secondary",
	},
	{
		render: (t, index) => {
			return (
				<Link
					key={index}
					to="/admin/pnl-org/alarm/new"
					className="text-def-primary bg-def-secondary p-4 shadow-lg cursor"
				>
					<div className="text-def-red relative flex justify-center">
						<Pulse></Pulse>
						<RiAlarmWarningLine className="text-5xl"></RiAlarmWarningLine>
					</div>
					<div className="text-white text-center">
						{t("button.create_alarm")}
					</div>
				</Link>
			);
		},
	},
];

const PDF = [{ field: "procedure", icon: <RiNewspaperLine /> }];

const ORG = [
	{ field: "org", icon: <RiOrganizationChart /> },
	{ field: "contract", icon: <RiBallPenLine /> },
];

const RIGHT_1 = [
	{ field: "step", icon: <RiCheckboxMultipleLine /> },
	{ field: "alert", icon: <RiBroadcastLine /> },
];

const RIGHT_2 = [
	{ field: "user", icon: <RiUserLine /> },
	{ field: "group", icon: <RiTeamLine /> },
	{ field: "unit", icon: <RiOrganizationChart /> },
	{ field: "emergency", icon: <RiAlarmWarningLine /> },
	{ field: "location", icon: <RiMapPin2Line /> },
	{ field: "news", icon: <RiArticleLine /> },
	{ field: "comment", icon: <RiChat1Line /> },
];

const Stats = ({ org_id }) => {
	const [loading, setLoading] = useState(false);
	const [stats, setStats] = useState();
	const { t } = useTranslation();
	const { user } = useAuth();

	const [year, setYear] = useState(new Date().getFullYear());
	const [years, setYears] = useState([]);

	const [sizes, setSizes] = useState([]);
	const [org, setOrg] = useState();

	useEffect(() => {
		const year = new Date().getFullYear();
		setYears([year - 2, year - 1, year]);
	}, []);

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);

			const response = await mainApi(
				year ? `/org/${org_id}/dashboard/${year}` : `/org/${org_id}/dashboard`
			);
			response && setStats(response.data);

			if (org_id) {
				const org_response = await mainApi(`/org/view/${org_id}/`);
				org_response && setOrg(org_response.data);
			}

			setLoading(false);
		};

		fetch();
	}, [org_id, year]);

	return (
		<div className="relative">
			{loading && <Loader />}
			{org_id && <OrgView {...org} />}
			<div className="mt-5 bg-white p-5">
				<div className="flex flex-wrap gap-2">
					{years.map((item) => (
						<div
							key={item}
							className={`p-2 border cursor-pointer ${
								year === item ? "bg-def-secondary text-white" : "bg-white"
							}`}
							onClick={() => {
								setYear(item);
							}}
						>
							{item}
						</div>
					))}
				</div>
			</div>
			{stats && (
				<div className="mt-5" key={year}>
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
						{TOP.map((props, index) => {
							if (!props) return <div></div>;

							const { field, icon, value, progress, progressColor, render } =
								props;

							if (render)
								return (
									["admin", "moderator"].includes(user?.role) &&
									render(t, index)
								);

							return (
								<CardIcon
									key={index}
									right={<div className="text-5xl opacity-30">{icon}</div>}
									middle={
										<div className="text-left self-center flex-1">
											<div className="text-xs leading-none">
												{t(`dashboard.${field}`)}
											</div>
											<div className="font-semibold text-xl">
												{value(stats)}
											</div>
										</div>
									}
									bottom={
										<div className="mt-2">
											{progress && (
												<Progress
													progress={progress(stats) * 100}
													height={"h-1"}
													hideLabel
													pulse
													color={progressColor}
												/>
											)}
										</div>
									}
								/>
							);
						})}
					</div>
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
						<div className="col-span-3">
							<div className="grid grid-cols-2 gap-16 p-4 bg-white shadow-lg">
								{org_id ? (
									<div>
										<h1 className="bordered">{t("title.top_users")}</h1>
										{Array.isArray(stats?.topUsers) &&
											stats.topUsers.map((item, index) => (
												<div
													key={index}
													className="mb-3 pb-3 border-b border-dashed"
												>
													<UserBrief {...item} rank={index + 1} />
												</div>
											))}
									</div>
								) : (
									<div>
										<h1 className="bordered">{t("title.org_stats")}</h1>
										{ORG.map(({ icon, field }, index) => (
											<CardIcon
												key={index}
												containerClass="shadow-none"
												left={<div className="text-5xl opacity-30">{icon}</div>}
												middle={
													<div className="text-right self-center flex-1">
														<div className="text-xs leading-none border-b mb-1 pb-1">
															{t(`dashboard.${field}`)}
														</div>
														<div className="font-semibold text-xl">
															{stats[field]}
														</div>
													</div>
												}
											/>
										))}
									</div>
								)}
								<div>
									<h1 className="bordered">{t("title.content_stats")}</h1>
									<div className="mb-8 pb-8">
										<div className="flex justify-between items-center">
											<div className="text-sm">
												{t("dashboard.training-content")}
											</div>
											<div className="font-semibold text-xl">
												{stats.trainingContent}
											</div>
										</div>
										<div className="flex justify-between items-center">
											<div className="text-sm">
												{t("dashboard.training-plan")}
											</div>
											<div className="font-semibold text-xl">
												{stats.trainingPlan}
											</div>
										</div>
										<div className="flex justify-between items-center mb-2">
											<div className="text-sm">{t("dashboard.training")}</div>
											<div className="font-semibold text-xl">
												<span className="text-base">
													{stats.trainingFocusable} /{" "}
												</span>
												{stats.training}
											</div>
										</div>
										<Progress
											progress={
												(stats.trainingFocusable / stats.training) * 100
											}
											height={"h-1"}
											hideLabel
										/>
									</div>
									<div className="mb-8 pb-8">
										<div className="flex justify-between items-center">
											<div className="text-sm">
												{t("dashboard.induction-template")}
											</div>
											<div className="font-semibold text-xl">
												{stats.inductionTemplate}
											</div>
										</div>
										<div className="flex justify-between items-center">
											<div className="text-sm">
												{t("dashboard.induction-plan")}
											</div>
											<div className="font-semibold text-xl">
												{stats.inductionPlan}
											</div>
										</div>
										<div className="flex justify-between items-center mb-2">
											<div className="text-sm">{t("dashboard.induction")}</div>
											<div className="font-semibold text-xl">
												<span className="text-base">
													{stats.inductionFocusable} /{" "}
												</span>
												{stats.induction}
											</div>
										</div>
										<Progress
											progress={
												(stats.inductionFocusable / stats.induction) * 100
											}
											height={"h-1"}
											hideLabel
										/>
									</div>
									<div className="mb-8 pb-8">
										<div className="flex justify-between items-center">
											<div className="text-sm">
												{t("dashboard.checklist-template")}
											</div>
											<div className="font-semibold text-xl">
												{stats.checklistTemplate}
											</div>
										</div>
										<div className="flex justify-between items-center mb-2">
											<div className="text-sm">{t("dashboard.checklist")}</div>
											<div className="font-semibold text-xl">
												<span className="text-base">
													{stats.checklistFocusable} /{" "}
												</span>
												{stats.checklist}
											</div>
										</div>
										<Progress
											progress={
												(stats.checklistFocusable / stats.checklist) * 100
											}
											height={"h-1"}
											hideLabel
										/>
									</div>
									<div className="mb-4">
										<div className="flex justify-between items-center">
											<div className="text-sm">{t("dashboard.ppe-item")}</div>
											<div className="font-semibold text-xl">
												{stats.ppeItem}
											</div>
										</div>
										<div className="flex justify-between items-center mb-2">
											<div className="text-sm">{t("dashboard.ppe")}</div>
											<div className="font-semibold text-xl">
												<span className="text-base">
													{stats.ppeFocusable} /{" "}
												</span>
												{stats.ppe}
											</div>
										</div>
										<Progress
											progress={(stats.ppeFocusable / stats.ppe) * 100}
											height={"h-1"}
											hideLabel
										/>
									</div>
								</div>
								{org_id && (
									<div className="col-span-2">
										<h1 className="bordered">{t("title.file_stats")}</h1>
										<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
											<Pie
												axio={`/org/stats/storage-usage/${org_id}`}
												name={t("org.file-stats")}
												height={300}
												renderLabel={({ name, value }) =>
													`${name}: ${formatSize(value)}`
												}
											/>
											<Pie
												axio={`/org/stats/file-size/${org_id}`}
												name={t("org.file-stats")}
												height={300}
												renderLabel={({ name, value }) =>
													`${name}: ${formatSize(value)}`
												}
												onFetched={setSizes}
											/>
										</div>
										<div className="mt-8">
											<FileStats sizes={sizes} />
										</div>
									</div>
								)}
							</div>
						</div>
						<div>
							<div className="bg-white mb-4">
								{PDF.map(({ icon, field }, index) => (
									<CardIcon
										key={index}
										containerClass="shadow-none"
										left={<div className="text-5xl opacity-30">{icon}</div>}
										middle={
											<div className="text-right self-center flex-1">
												<div className="text-xs leading-none border-b mb-1 pb-1">
													{t(`dashboard.${field}`)}
												</div>
												<div className="font-semibold text-xl">
													{stats[field]}
												</div>
											</div>
										}
									/>
								))}
							</div>
							<div className="bg-white mb-4">
								{RIGHT_1.map(({ icon, field }, index) => (
									<CardIcon
										key={index}
										containerClass="shadow-none"
										left={<div className="text-5xl opacity-30">{icon}</div>}
										middle={
											<div className="text-right self-center flex-1">
												<div className="text-xs leading-none border-b mb-1 pb-1">
													{t(`dashboard.${field}`)}
												</div>
												<div className="font-semibold text-xl">
													{stats[field]}
												</div>
											</div>
										}
									/>
								))}
							</div>
							<div className="bg-white">
								{RIGHT_2.map(({ icon, field }, index) => (
									<CardIcon
										key={index}
										containerClass="shadow-none"
										left={<div className="text-5xl opacity-30">{icon}</div>}
										middle={
											<div className="text-right self-center flex-1">
												<div className="text-xs leading-none border-b mb-1 pb-1">
													{t(`dashboard.${field}`)}
												</div>
												<div className="font-semibold text-xl">
													{stats[field]}
												</div>
											</div>
										}
									/>
								))}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Stats;
